@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';
@use '../../styles/sizes';

.root {
  margin: 0 0 0 0;
  padding: 180px 12px 180px 12px;
  position: relative;
  z-index: 1;

  @include mixins.bp-md {
    flex-direction: column;
  }
}

.top {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;
}

.card {
  border-radius: 12px;
  box-shadow: 0px 25px 40px 10px rgba(0, 0, 0, 0.2);
  max-width: 1280px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frameWrapper {
  position: relative;
  padding-top: 56.25%;
}

.video {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.playButton {
  align-items: center;
  appearance: none;
  background: transparent;
  border: 0;
  bottom: -42px;
  color: colors.$tw-blue-500;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  gap: 12px;
  justify-content: center;
  cursor: pointer;
  left: 12px;
  left: 50%;
  padding: 6px;
  position: absolute;
  transform: translate(-50%, 0);
  z-index: 1;
}

.playButton svg {
  color: colors.$tw-blue-500;
  fill: colors.$tw-blue-500;
  width: 18px;
}

.bottom {
  display: flex;
  margin: 120px auto 0 auto;
  max-width: sizes.$container-width-x-wide;
  padding: 0 12px;
}

.left {
  flex: 0 0 100%;

  @include mixins.bp-md {
    flex: 0 0 50%;
  }
}

.right {
  display: none;
  flex: 0 0 50%;
  position: relative;

  @include mixins.bp-md {
    display: block;
  }
}

.text {
  margin: 30px 0 0 0;
}

.image {
  position: absolute;
  right: -190px;
  top: -190px;
  width: 480px;
  pointer-events: none;
}

.image img {
  width: 100%;
}

// icons
.cross {
  color: colors.$inchworm;
  height: 36px;
  position: absolute;
  right: 0;
  top: -25px;
  width: 36px;

  @include mixins.bp-md {
    bottom: -76px;
    height: 46px;
    left: 86px;
    right: unset;
    top: unset;
    width: 46px;
  }
}

.donut {
  border: 5px solid colors.$medium-purple;
  border-radius: 50%;
  height: 22px;
  position: absolute;
  right: 30px;
  top: -45px;
  width: 22px;

  @include mixins.bp-md {
    border-width: 8px;
    bottom: 154px;
    height: 42px;
    left: 243px;
    right: unset;
    top: unset;
    width: 42px;
  }
}
