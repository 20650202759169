@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';

.root {
  background: colors.$lavender-blush;
  padding: 120px 12px 120px 12px;
  position: relative;
}

.content {
  margin: 0 auto;
}

.heading {
  font-size: fonts.$size-24;
  color: colors.$carnation;
  font-family: var(--font-brand);
  font-weight: 900;
  margin: 0 0 0 0;
  text-align: center;

  @include mixins.bp-sm {
    font-size: fonts.$size-30;
  }
}

.byline {
  font-size: fonts.$size-18;
  color: colors.$mine-shaft;
  font-family: var(--font-brand);
  font-weight: 400;
  margin: 12px 0 0 0;
  text-align: center;

  @include mixins.bp-sm {
    font-size: fonts.$size-20;
  }
}

.card {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin: 36px auto 0;
  max-width: 640px;
  position: relative;
  z-index: 2;

  @include mixins.bp-sm {
    flex-direction: row;
  }
}

.column {
  background: colors.$white;
  border-radius: 5px;
  box-shadow: 0px 5px 15px rgba(colors.$black, 0.1);
  flex: 0 0 50%;
  padding: 24px 24px;

  @include mixins.bp-sm {
    padding: 36px 30px 48px;
  }
}

.price {
  color: colors.$tw-gray-900;
  font-family: var(--font-brand);
  font-weight: 700;
  letter-spacing: -0.05em;
  text-align: center;
  font-size: fonts.$size-36;

  @include mixins.bp-sm {
    font-size: fonts.$size-60;
  }
}

.priceByline {
  color: colors.$tw-gray-700;
  font-family: var(--font-brand);
  font-weight: 600;
  text-align: center;
  font-size: fonts.$size-14;

  @include mixins.bp-sm {
    font-size: fonts.$size-16;
  }
}

.squiggle {
  display: block;
  fill: colors.$carnation;
  margin: 12px auto 0;
}

.list {
  list-style: none;
  margin: 18px 0 0 0;
  padding: 0;
}

.list li {
  margin: 15px 0;
  padding: 0 0 0 20px;
  position: relative;
  text-indent: -26px;
}

.list svg {
  fill: colors.$carnation;
  height: 11px;
  margin: 0 12px 0 0;
  width: 13px;
}

.list span {
  color: colors.$tw-gray-700;
  font-family: var(--font-brand);
  font-weight: 400;
  font-size: fonts.$size-14;

  @include mixins.bp-sm {
    font-size: fonts.$size-16;
  }
}

.button {
  @include mixins.bp-sm {
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    bottom: -25px;
  }
}

.curlyArrow {
  bottom: 82px;
  fill: colors.$carnation;
  height: 161px;
  left: 50%;
  position: absolute;
  transform: translate(-480px, 0px);
  width: 369px;

  @include mixins.bp-sm {
    transform: translate(-560px, 0px);
  }
}

.blueSquiggle {
  color: colors.$cornflower-blue;
  height: 53px;
  position: absolute;
  right: 0;
  top: 50px;
  transform: translate(0px, 0px);
  width: 255px;

  @include mixins.bp-sm {
    bottom: 50%;
    top: unset;
  }
}
