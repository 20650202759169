@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';

.root {
  list-style: none;
  margin: 30px 0 0 0;
  padding: 0;
}

.item {
  color: colors.$tw-gray-900;
  font-family: var(--font-brand);
  font-weight: 400;
  margin: 18px 0 0 0;
  padding: 0 0 0 34px;
  position: relative;
  text-indent: -34px;
  font-size: fonts.$size-18;

  @include mixins.bp-md {
    padding: 0 0 0 40px;
    text-indent: -40px;
  }
}

.item:first-child {
  margin-top: 0;
}

.tick {
  background-color: colors.$tw-red-700;
  border-radius: 50%;
  display: inline-block;
  height: 22px;
  margin-right: 12px;
  position: relative;
  top: 4px;
  width: 22px;

  @include mixins.bp-md {
    height: 26px;
    top: 6px;
    width: 26px;
  }
}

.tick svg {
  fill: colors.$white;
  height: 12px;
  left: 5px;
  position: absolute;
  top: 6px;
  width: 12px;

  @include mixins.bp-md {
    height: 14px;
    left: 6px;
    top: 6px;
    width: 14px;
  }
}
