$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$container-width-xx-small: 320px;
$container-width-x-small: 450px;
$container-width-small: 580px;
$container-width-medium: 720px;
$container-width-wide: 960px;
$container-width-x-wide: 1260px;

$hero-side-bar-visible: 1360px;
