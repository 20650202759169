// Cheat sheet
// 12px | t-xs
// 14px | t-sm
// 16px | t-base
// 18px | t-lg
// 20px | t-xl
// 24px | t-2xl
// 30px | t-3xl
// 36px | t-4xl
// 48px | t-5xl
// 60px | t-6xl
// 72px | t-7xl
// 96px | t-8xl
// 128px | t-9xl

$system: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
  Helvetica Neue, sans-serif;

$size-12: 0.75rem;
$size-14: 0.875rem;
$size-16: 1rem;
$size-18: 1.125rem;
$size-20: 1.25rem;
$size-24: 1.5rem;
$size-30: 1.875rem;
$size-36: 2.25rem;
$size-48: 3rem;
$size-60: 3.75rem;
$size-72: 4.5rem;
$size-96: 6rem;
$size-128: 8rem;
