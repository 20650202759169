@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';
@use '../../styles/sizes';

.root {
  background: colors.$white;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  max-width: sizes.$container-width-x-wide;
  padding: 120px 12px;
  position: relative;

  @include mixins.bp-md {
    flex-direction: row;
  }
}

.left {
  flex: 0 1 50%;
  position: relative;
  z-index: 2;
}

.right {
  flex: 0 1 50%;
  position: relative;
  z-index: 1;
}

.imageWrapper {
  border-radius: 20px;
  filter: drop-shadow(0px 5px 15px rgba(58, 58, 58, 0.1));
  overflow: hidden;
  position: relative;
  top: 80px;
  transform: matrix(1, 0.23, -0.67, 1, 0, 0);

  @include mixins.bp-md {
    top: 140px;
  }

  @include mixins.bp-lg {
    top: 140px;
    right: -160px;
  }
}

.image {
  max-width: 100%;
}

.text {
  margin: 30px 0 0 0;
}

// icons
.cross {
  color: colors.$inchworm;
  height: 36px;
  position: absolute;
  right: 0;
  top: -25px;
  width: 36px;

  @include mixins.bp-md {
    bottom: -25px;
    height: 46px;
    left: -100px;
    right: unset;
    top: unset;
    width: 46px;
  }
}

.donut {
  border: 5px solid colors.$medium-purple;
  border-radius: 50%;
  height: 22px;
  position: absolute;
  right: 30px;
  top: -45px;
  width: 22px;

  @include mixins.bp-md {
    border-width: 8px;
    bottom: 25px;
    height: 42px;
    left: -60px;
    right: unset;
    top: unset;
    width: 42px;
  }
}

.yellowCircle {
  background: colors.$ronchi;
  border-radius: 50%;
  height: 81px;
  left: -30px;
  position: absolute;
  top: 40px;
  width: 81px;

  @include mixins.bp-md {
    height: 101px;
    left: 0px;
    width: 101px;
  }
}

.time {
  color: colors.$white;
  height: 54px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 54px;

  @include mixins.bp-md {
    height: 64px;
    width: 64px;
  }
}

.blueCircle {
  background: colors.$picton-blue;
  border-radius: 50%;
  bottom: -35px;
  height: 106px;
  position: absolute;
  right: -30px;
  width: 106px;

  @include mixins.bp-md {
    height: 126px;
    right: 0px;
    width: 126px;
  }
}

.gamePad {
  background-position: 50%;
  background-repeat: no-repeat;
  color: colors.$white;
  height: 65px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65px;

  @include mixins.bp-md {
    height: 75px;
    width: 73px;
  }
}
