@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';

.root {
  color: colors.$tw-gray-900;
  font-family: var(--font-system);
  font-weight: 400;
  line-height: 1.5;
  margin: 0 0 0 0;
  padding: 0;
  position: relative;
  font-size: fonts.$size-18;
}

.center {
  text-align: center;
}

.root p,
.root li {
  margin: 18px 0 0 0;
  padding: 0;
}

.root p:first-of-type,
.root li:first-of-type {
  margin: 0;
}

.root a {
  font-size: fonts.$size-18;
}

.root em {
  font-weight: 700;
  text-decoration-color: colors.$carnation;
  font-style: normal;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
  text-decoration-style: wavy;
  margin: 0 3px;
}
