@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';
@use '../../styles/sizes';

.root {
  background: colors.$lily-white;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  max-width: sizes.$container-width-x-wide;
  padding: 120px 12px;
  position: relative;

  @include mixins.bp-md {
    flex-direction: row;
  }
}

.left {
  flex: 0 1 50%;
}

.right {
  flex: 0 1 50%;
  position: relative;

  @include mixins.bp-md {
    min-height: 500px;
  }
}

.imageWrapper {
  position: relative;

  @include mixins.bp-md {
    right: -300px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  @include mixins.bp-xl {
    width: 841px;
  }
}

.image {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.text {
  margin: 30px 0 0 0;
}

// Shapes
.disc {
  background: colors.$heliotrope;
  border-radius: 50%;
  bottom: -35px;
  height: 189px;
  left: 60px;
  position: absolute;
  width: 189px;

  @include mixins.bp-md {
    left: 150px;
    bottom: -85px;
  }
}

.squiggle {
  color: colors.$turquoise-blue;
  height: 53px;
  position: absolute;
  right: -30px;
  top: 269px;
  width: 255px;

  @include mixins.bp-md {
    bottom: -60px;
    top: -110px;
  }
}

.cross {
  color: colors.$picton-blue;
  height: 36px;
  position: absolute;
  right: 23px;
  top: -134px;
  width: 36px;
  @include mixins.bp-md {
    right: 323px;
  }
}

.donut {
  border: 5px solid colors.$heliotrope;
  border-radius: 50%;
  height: 22px;
  position: absolute;
  right: -15px;
  top: -71px;
  width: 22px;

  @include mixins.bp-md {
    right: 430px;
  }
}
