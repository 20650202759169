@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';
@use '../../styles/sizes';

.root {
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;

  @include mixins.bp-sm {
    height: 60px;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: sizes.$container-width-x-wide;
  padding: 12px 6px 0 6px;

  @include mixins.bp-sm {
    padding: 0 12px;
  }
}

.hamburger {
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  fill: colors.$mine-shaft;
  height: 50px;
  padding: 14px;
  position: relative;
  width: 50px;

  @include mixins.bp-md {
    display: none;
  }
}

.list {
  background: colors.$white;
  border-radius: 12px;
  box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.2);
  display: none;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 18px;
  list-style: none;
  margin: 0;
  max-width: 320px;
  padding: 30px;
  position: absolute;
  right: 12px;
  top: 60px;
  transform: translate3d(0%, 0, 0);
  width: calc(100% - 24px);
  z-index: 1;

  @include mixins.bp-md {
    background: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: flex-end;
    margin: 0 0 0 12px;
    max-width: 100%;
    padding: 0;
    position: relative;
    right: 0;
    top: 0;
  }

  @include mixins.bp-lg {
    gap: 18px;
  }
}

.mobileMenuOpen .list {
  display: flex;
}

.item {
  flex: 0 0 auto;
  height: 100%;
}

.link {
  border-bottom: 2px solid transparent;
  color: colors.$mine-shaft;
  display: block;
  font-family: var(--font-brand);
  font-size: fonts.$size-16;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-align: center;

  @include mixins.bp-md {
    font-size: fonts.$size-16;
    height: 36px;
    line-height: 36px;
    padding: 0 6px;
  }
}

.link:hover,
.active {
  color: colors.$tw-red-600;
  text-decoration: none;

  @include mixins.bp-md {
    border: 0;
    border-bottom: 2px solid colors.$tw-red-600;
    color: colors.$mine-shaft;
  }
}

.button {
  background: colors.$white;
  border-radius: 100px;
  box-shadow: 0px 5px 15px rgba(colors.$mine-shaft, 0.1);
  color: colors.$mine-shaft;
  display: block;
  font-family: var(--font-brand);
  font-size: fonts.$size-16;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
  white-space: nowrap;

  @include mixins.bp-md {
    font-size: fonts.$size-16;
    padding: 0 24px;
    height: 36px;
    line-height: 36px;
  }
}

.button:hover {
  background-color: colors.$gray-100;
  text-decoration: none;
  transform: translate(0, 1px);
}

.primary {
  background: colors.$tw-red-700;
  color: colors.$white;
}

.primary:hover {
  background: colors.$tw-red-800;
}

.bubbleWrapper {
  position: relative;
}

.bubble {
  background: colors.$white;
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(colors.$black, 0.15);
  color: colors.$tw-gray-900;
  display: block;
  font-family: var(--font-brand);
  font-weight: 400;
  left: 0;
  opacity: 0;
  padding: 12px 24px;
  pointer-events: none;
  position: absolute;
  top: 36px;
  transform: scale(0.8, 0.8) translate(0, -20px);
  transition: 300ms all ease-out;
  white-space: nowrap;
}

.bubbleWrapper:hover .bubble {
  opacity: 1;
  transform: scale(1, 1) translate(0, 0);
}

.bubble::before {
  background: colors.$white;
  border-radius: 2px;
  content: '';
  height: 12px;
  left: 50%;
  position: absolute;
  top: -6px;
  transform: translateX(-50%) rotate(45deg);
  width: 12px;
}

.logo {
  height: 21px;
  width: 181px;

  @include mixins.bp-sm {
  }
}
