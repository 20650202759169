@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';

.root {
  position: relative;
}

.heading {
  color: colors.$black;
  font-family: var(--font-brand);
  font-size: fonts.$size-30;
  font-weight: 900;
  line-height: 1.3;
  margin: 0;

  @include mixins.bp-md {
    font-size: fonts.$size-36;
    line-height: 1.2;
  }
}

.category {
  font-size: fonts.$size-18;
  font-weight: 900;
  color: colors.$tw-red-700;
  margin: 0 0 30px 0;
}
