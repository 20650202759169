@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';
@use '../../styles/sizes';

.root {
  padding: 60px 12px;

  @include mixins.bp-md {
    padding: 240px 12px;
  }
}

.wrapper {
  margin: 0 auto;
  max-width: sizes.$container-width-x-wide;
  padding: 0 12px;
  position: relative;
  width: 100%;
}

.columns {
  display: flex;
  flex-direction: column;
  @include mixins.bp-md {
    flex-direction: row;
  }
}

.left {
  flex: 1 1 auto;

  @include mixins.bp-md {
    flex: 1 1 60%;
  }
}

.right {
  flex: 1 1 auto;
  position: relative;

  @include mixins.bp-md {
    flex: 1 1 40%;
  }
}

.imageWrapper {
  margin: 30px auto 0;
  max-width: 300px;

  @include mixins.bp-md {
    max-width: none;
    margin: 0 auto 0;
    top: 0;
    position: absolute;
    right: 0;
  }

  @include mixins.bp-lg {
    top: -50px;
  }

  @include mixins.bp-xl {
    top: -100px;
  }
}

.image {
  max-width: 100%;
  height: 100%;
}

.curlyArrow {
  fill: colors.$carnation;
  height: 161px;
  left: 500;
  position: absolute;
  top: 0;
  transform: translate(-480px, 0px);
  width: 369px;

  @include mixins.bp-sm {
    transform: translate(-380px, -60px) rotate(190deg) scaleX(-1);
  }
}

.text {
  margin: 30px 0 0 0;
}
