@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';
@use '../../styles/sizes';

.root {
  background: colors.$lily-white;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  max-width: sizes.$container-width-x-wide;
  padding: 120px 12px;
  position: relative;

  @include mixins.bp-md {
    flex-direction: row;
  }
}

.left {
  flex: 0 1 50%;
  position: relative;

  @include mixins.bp-md {
    min-height: 500px;
  }
}

.right {
  display: flex;
  flex: 0 1 50%;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  position: relative;
}

.imageWrapper {
  position: relative;

  @include mixins.bp-md {
    right: -300px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  @include mixins.bp-xl {
    width: 841px;
  }
}

.image {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.text {
  margin: 30px 0 0 0;
}

// Shapes
.disc {
  background: colors.$turquoise-blue-2;
  border-radius: 50%;
  bottom: -35px;
  height: 250px;
  right: -449px;
  position: absolute;
  width: 250px;

  @include mixins.bp-md {
    bottom: -502px;
    height: 375px;
    width: 375px;
  }
}

.box {
  background: rgba(colors.$heliotrope, 0.1);
  border-radius: 12px;
  height: 156px;
  position: absolute;
  right: 50px;
  top: 100px;
  transform: rotate(-65deg);
  width: 156px;

  @include mixins.bp-md {
    top: 20px;
  }
}
