@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';

.root {
  background: colors.$blue-marguerite;
  padding: 80px 30px;
  position: relative;
  text-align: center;
}

.logo {
  margin: 0 auto;
  text-align: center;
  width: 220px;
}

.logo img {
  width: 100%;
  max-width: 100%;
}

.list {
  list-style: none;
  margin: 30px 0 0 0;
  padding: 0;
}

.list li {
  padding: 6px 12px;
}

.link {
  color: colors.$white;
  font-family: var(--font-brand);
  font-size: fonts.$size-16;
  font-weight: 600;
  text-decoration: underline;
}

.link svg {
  fill: colors.$white;
}

.socials {
  display: flex;
  gap: 10px;
  justify-content: center;
  list-style: none;
  margin: 30px 0 0 0;
  padding: 0;
}

.socials li {
  border: 3px solid colors.$white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.socials a {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.instagram {
  width: 20px;
}

.twitter {
  width: 18px;
}

.facebook {
  width: 14px;
}

.copyright {
  margin-top: 30px;
}

.copyright,
.copyright a {
  color: colors.$white;
  font-family: var(--font-brand);
  font-size: fonts.$size-16;
  font-weight: 400;
  text-decoration: none;
}

.circle {
  background: colors.$starship;
  border-radius: 50%;
  height: 200px;
  position: absolute;
  right: -120px;
  top: -100px;
  width: 200px;

  @include mixins.bp-md {
    height: 300px;
    right: -100px;
    width: 300px;
  }

  @include mixins.bp-lg {
    height: 425px;
    right: -100px;
    width: 425px;
  }
}
