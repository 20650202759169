@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';

.root {
  align-items: center;
  background: colors.$peach;
  display: flex;
  justify-content: center;
  padding: 60px 12px 30px;
  position: relative;

  @include mixins.bp-sm {
    height: 470px;
    padding: 80px 30px 60px;
  }

  @include mixins.bp-lg {
    height: 550px;
  }
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 18px;
  pointer-events: none; // Remember button, the image makes the content area huge!
  text-align: center;
  z-index: 2;

  @include mixins.bp-sm {
    flex-direction: row;
  }
}

.imageContainer {
  position: relative;
  flex: 1 1 auto;

  @include mixins.bp-sm {
    position: absolute;
  }

  @include mixins.bp-lg {
    flex: 0 0 50%;
    position: relative;
  }
}

.imageContainer picture {
  display: block;
  position: relative;
  top: 10px;
  left: -50px;
  animation: skewAnimation 5s linear infinite;
  transform: skewX(0deg);
  transform-origin: 0 100%;

  @include mixins.bp-sm {
    top: 138px;
    left: -90px;
  }

  @include mixins.bp-lg {
    top: 100px;
    left: -50px;
  }
}

@keyframes skewAnimation {
  0% {
    transform: skewX(-1deg);
  }
  50% {
    transform: skewX(1deg);
  }
  100% {
    transform: skewX(-1deg);
  }
}

.imageContainer picture::after {
  background: #4a5a67;
  border-radius: 50%;
  content: '';
  display: block;
  height: 10%;
  left: 27%;
  opacity: 0.2;
  position: absolute;
  rotate: -2deg;
  top: 91%;
  width: 55%;
  z-index: -1;
}

.imageContainer img {
  height: 220px;
  width: 248px;

  @include mixins.bp-lg {
    height: 440px;
    width: 496px;
  }
}

.textContainer {
  padding: 0;
  pointer-events: all;
  right: 0;
  width: 260px;

  @include mixins.bp-sm {
    flex: 0 0 100%;
    padding: 0 0 0 100px;
    right: 0;
    width: auto;
  }

  @include mixins.bp-lg {
    flex: 0 0 50%;
    padding: 0 24px 0 0;
    right: unset;
  }
}

.heading {
  color: colors.$black;
  font-family: var(--font-brand);
  font-size: fonts.$size-24;
  font-weight: 900;
  line-height: 1.3;
  max-width: 700px;
  text-align: center;

  @include mixins.bp-sm {
    font-size: fonts.$size-30;
    max-width: 500px;
  }

  @include mixins.bp-lg {
    font-size: fonts.$size-36;
  }
}

.byline {
  color: rgba(colors.$mine-shaft, 0.9);
  font-family: var(--font-brand);
  font-size: fonts.$size-18;
  font-weight: 400;
  line-height: 1.5;
  margin: 12px 0 0 0;
  max-width: 700px;
  text-align: center;

  @include mixins.bp-sm {
    margin: 18px 0 0 0;
    max-width: 500px;
  }

  @include mixins.bp-lg {
    font-size: fonts.$size-20;
    margin: 30px 0 0 0;
  }
}

.underlineWrapper {
  position: relative;
  z-index: 1;
}

.underlineWrapper span {
  position: relative;
  z-index: 2;
}

.underline {
  bottom: 0;
  fill: colors.$carnation;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  width: 200px;
  z-index: 1;

  @include mixins.bp-md {
    bottom: 0px;
  }
}

.button {
  margin: 24px auto 0px auto;
  max-width: 300px;
  pointer-events: all;

  @include mixins.bp-sm {
    margin: 36px auto 0 auto;
    max-width: 300px;
  }
}

.download {
  margin: 36px auto 0 auto;
  display: block;
  width: 180px;
  height: 60px;
}
